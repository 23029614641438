.h3, h3 {
   font-size: calc(0.9rem + .6vw);
}
.rdw-dropdown-optionwrapper {
   z-index: 1001;
 }
 
 .rdw-editor-toolbar {
   position: sticky;
   top: 0;
   padding: 5px;
   padding-top: 11px;
   z-index: 1000;
   border: none;
   justify-content: center !important;
   border-bottom: solid 1px var(--chakra-colors-chakra-border-color);
 }
 
 .rdw-inline-wrapper{
   box-sizing: content-box;
 }
 .rdw-editor-main{
   max-width: var(--chakra-sizes-3xl);
   margin: 0 auto;
   padding-bottom: var(--chakra-space-8);
 }
 .rdw-option-wrapper{
   padding: 15px;
 }
.rdw-option-wrapper:hover, 
.rdw-dropdown-wrapper:hover,
.rdw-link-modal-btn:hover{
   box-shadow: none;
   border-color: #999;
}
.rdw-option-active{
    box-shadow: none;
    border-color: #999;
}
.rdw-option-wrapper, 
.rdw-dropdown-wrapper{
   border-radius: 6px;
}
.rdw-link-modal-btn{
   border-radius: 3px;
}

.h1, h1 {
  font-size: calc(1.5rem + 1vw);
}

.h2, h2 {
  font-size: calc(1.3rem + .8vw);
}

.h3, h3 {
  font-size: calc(1.1rem + .6vw);
}

.h4, h4 {
  font-size: calc(0.9rem + .4vw);
}

.h5, h5 {
  font-size: calc(0.7rem + .2vw);
}
